import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import logo from '../assets/SilmexCoLogo.png';
import { useNavigate } from 'react-router-dom';

export function SilmexCoLogo() {
  const navigate = useNavigate();

  const navigationItemClicked = () => {
    navigate('/', { state: { scrollTo: 'home' } });
  };

  return (
    <ScrollLink
      to="home"
      onClick={() => navigationItemClicked()}
      underline="none"
      style={{
        cursor: 'pointer'
      }}>
      <img src={logo} width="100" height="60" />
    </ScrollLink>
  );
}
