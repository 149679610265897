import { makeStyles } from '@material-ui/core/styles';

export const useUnderlineStyle = makeStyles({
  underlined: {
    position: 'relative',
    display: 'inline-block',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      bottom: '-20px', // adjust this value to change the distance between the text and the underline
      transform: 'translateX(-50%)',
      width: 'calc(100% - 200px)', // adjust this value to change the length of the underline
      borderBottom: '2px solid #000' // adjust this value to change the color and style of the underline
    }
  },
  container: {
    textAlign: 'center' // center the text within the container
  }
});
