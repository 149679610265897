import { Typography, Grid, Box } from '@mui/material';
import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import DownloadIcon from '../assets/Download.png';
import { Link } from 'react-router-dom';
import FileDownloader from './FileDownloader';

const WhiteBox = (props) => {
  const { text, src, downloadableFiles, downloadableFilesTitles } = props;

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200" data-aos-delay="50">
      <Grid
        container
        justifyContent={'center'}
        bgcolor={'white'}
        borderRadius={5}
        alignItems={'center'}
        sx={{
          padding: { xs: 4, md: 5 },
          marginBottom: { xs: 2, md: 5 },
          gap: { xs: 2, md: 5 }
        }}>
        {src && (
          <Grid item xs={12} md={1} sx={{ textAlign: { xs: 'center', md: 'unset' } }}>
            <img src={src} height="50px" />
          </Grid>
        )}
        <Grid item xs={12} md={src ? 10 : 12}>
          <Typography
            sx={{ textAlign: { md: 'justify' } }}
            fontWeight={200}
            whiteSpace={'pre-line'}>
            {text}
          </Typography>
        </Grid>
        {downloadableFiles &&
          downloadableFiles.map((file, index) => (
            <Box key={index}>
              <FileDownloader
                file={file}
                downloadableFilesTitles={downloadableFilesTitles}
                index={index}
              />
            </Box>
          ))}
      </Grid>
    </div>
  );
};

export default WhiteBox;
