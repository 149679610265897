import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { InformationBox } from '../components/InformationBox';
import { TopNavigation } from '../components/TopNavigation';
import { useParams } from 'react-router-dom';
import theme from '../theme';
import useProducts from '../productsRepository';
import { Element, Link as ScrollLink } from 'react-scroll';
import GetInTouchButton from '../components/productDetails/GetInTouchButton';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import WhiteBox from '../components/WhiteBox';
import mouse from '../assets/Mouse.png';

export function ProductDetail() {
  const { id } = useParams();
  const { t } = useTranslation();

  const { getProductById } = useProducts();
  const product = getProductById(id);

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();
  }, []);

  return (
    <Container
      maxWidth={false}
      disableGutters
      style={{ backgroundColor: theme.palette.primary.background }}>
      <TopNavigation />

      <Box display={'flex'} flexDirection={'column'}>
        <Box sx={{ mt: 7, textAlign: 'center' }}>
          <Typography
            fontWeight={600}
            sx={{ fontSize: { xs: '10px', md: '17px' } }}
            color={'secondary.main'}>
            {t('productDetails.quality')}
          </Typography>
          <Typography
            fontWeight={600}
            sx={{ marginTop: '5px', fontSize: { xs: '25px', md: '40px' } }}>
            {product.title}
          </Typography>
        </Box>

        <Box display={'flex'} sx={{ mt: 5 }} justifyContent="center">
          <img src={product.images[0]} style={{ maxWidth: '45vw', maxHeight: '45vh' }} />
        </Box>

        <Box sx={{ mt: 5 }}>
          <ScrollLink to="description" smooth={true} duration={500}>
            <Box
              display={'flex'}
              justifyContent="center"
              alignItems="center"
              flexDirection="column">
              <img
                src={mouse}
                alt="Mouse"
                style={{ width: '50px', height: 'auto', cursor: 'pointer' }}
              />
              <Typography variant="caption" color="textSecondary" sx={{ cursor: 'pointer', mt: 1 }}>
                Scroll
              </Typography>
            </Box>
          </ScrollLink>
        </Box>

        <Element name="description">
          <Box display={'flex'} flexDirection={'row'} justifyContent="center" flexWrap={'wrap'}>
            <Box
              mt={5}
              sx={{
                maxWidth: { xs: '100%', md: '50%' },
                padding: 5
              }}
              alignItems={'center'}>
              {product.descriptions.map((description) => (
                <WhiteBox
                  key={description}
                  text={description.text}
                  downloadableFiles={description.filesToDownload}
                  downloadableFilesTitles={description.filesToDownloadTitles}
                />
              ))}
            </Box>
          </Box>
        </Element>

        <Box sx={{ mt: 3, mx: { xs: 5, md: 0 } }}>
          {product.details.length > 0 && (
            <Box
              display={'flex'}
              justifyContent={'center'}
              flexDirection={'column'}
              alignItems="center"
              gap={10}>
              {product.details.map((detail) => (
                <InformationBox
                  key={detail.id}
                  title={detail.title}
                  description={detail.description}
                  image={detail.image}
                  characteristicsFiles={detail.characteristicsFiles}
                  characteristicsTitles={detail.characteristicsTitles}
                  sx={{ width: { xs: '100%', md: '50%' } }}
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>

      <Box sx={{ mt: 20 }}>
        <GetInTouchButton product={product} />
      </Box>
    </Container>
  );
}
