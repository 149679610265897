import { FileDownload } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import FileDownloader from './FileDownloader';

export function InformationBox(props) {
  const { title, description, image, characteristicsFiles, characteristicsTitles, sx } = props;
  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} sx={sx}>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <Box display={'flex'} justifyContent={'center'} paddingRight={2}>
          <img src={image} height="30" />
        </Box>

        <Typography variant="h5" fontWeight={'bold'}>
          {title}
        </Typography>
      </Box>

      <Typography
        textAlign={'justify'}
        color={'accent.text'}
        paddingTop={3}
        whiteSpace={'pre-line'}>
        {description}
      </Typography>

      <Box flexWrap={'wrap'} display="flex" flexDirection="row" gap={2} mt={2}>
        {characteristicsFiles &&
          characteristicsFiles.map((file, index) => (
            <Box key={index}>
              <FileDownloader
                file={file}
                downloadableFilesTitles={characteristicsTitles}
                index={index}
              />
            </Box>
          ))}
      </Box>
    </Box>
  );
}
