import { useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import BenevelliLogo from '../assets/Benevelli.png';
import CFRLogo from '../assets/CFR.png';
import CWLogo from '../assets/CW.png';
import DMCLogo from '../assets/DMC.svg';
import PBMLogo from '../assets/PBM.png';
import TitleImage from '../assets/TitleImage.png';

const useStyles = makeStyles((theme) => ({
  img: {
    width: '50%',
    [theme.breakpoints.up('md')]: {
      width: '70%'
    }
  }
}));

export const Landing = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const partnerImages = [BenevelliLogo, CFRLogo, CWLogo, DMCLogo, PBMLogo];

  return (
    <Box
      sx={{ height: { xs: `auto`, lg: windowSize.current[1] - 70 } }}
      display="flex"
      flexDirection="column">
      <Grid container rowSpacing={3} style={{ flexGrow: 1 }}>
        <Grid container item alignItems={'center'}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                paddingX: { xs: '30px', md: '100px' }
              }}
              paddingTop={2}
              flexDirection={'column'}
              minWidth={'50%'}>
              <Typography
                color={'primary.text'}
                fontWeight={'bold'}
                lineHeight={1}
                sx={{ fontSize: { xs: '30px', md: '60px' } }}>
                {t('landing.title')}
              </Typography>

              <Typography color={'primary.text'} mt={3}>
                {t('landing.description')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100%" py={5}>
              <img src={TitleImage} className={classes.img} />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid container bgcolor={'white'}>
        <Box
          display={'flex'}
          width="100%"
          flexWrap={'wrap'}
          justifyContent={'space-between'}
          marginX={5}>
          {partnerImages.map((image, index) => {
            return (
              <Box
                key={index}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: { xs: '100%', md: '20%' },
                  height: { xs: '80px', md: '100px' }
                }}>
                <img src={image} height="30" />
              </Box>
            );
          })}
        </Box>
      </Grid>
    </Box>
  );
};
