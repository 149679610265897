import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, AppBar, Toolbar, IconButton, MenuItem, Container, Button } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';
import MenuIcon from '@mui/icons-material/Menu';
import { ElevationScroll } from './ElevationScroll';
import theme from '../theme.js';
import { LanguageSelect } from './LanguageSelect.js';
import { SilmexCoLogo } from './SilmexCoLogo';
import { useNavigate } from 'react-router-dom';
import phone from '../assets/Phone.png';
import { useMediaQuery } from '@mui/material';
import { activeItemStyle } from '../styles/activeItemStyle';

const phoneNumberToCall = '+359887751500';
const phoneNumberToDisplay = '+359 88 775 1500';

export function TopNavigation() {
  const classes = activeItemStyle();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isMdOrLessScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const isLandingPage = window.location.pathname === '/';

  const pages = [
    {
      href: 'home',
      name: t('navigation.home')
    },
    {
      href: 'aboutUs',
      name: t('navigation.aboutUs')
    },
    {
      href: 'products',
      name: t('navigation.products')
    },
    {
      href: 'contact',
      name: t('navigation.contact')
    }
  ];

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const phoneNumberClicked = () => {
    document.location.href = 'tel:' + phoneNumberToCall;
  };

  const navigationItemClicked = (page) => {
    !isLandingPage && navigate('/', { state: { scrollTo: page.href } });
  };

  return (
    <ElevationScroll>
      <AppBar
        position="sticky"
        elevation={0}
        style={{ background: theme.palette.primary.background }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* Small Screen Menu */}
            <Box>
              <IconButton
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit">
                <MenuIcon />
              </IconButton>
              {isMenuOpen && (
                <Box
                  id="menu-appbar"
                  sx={{
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    zIndex: 1,
                    backgroundColor: '#fff',
                    padding: '1rem',
                    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)'
                  }}>
                  {pages.map((page) => (
                    <MenuItem key={page.href}>
                      <ScrollLink
                        key={page.href}
                        to={page.href}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        onClick={handleCloseMenu}>
                        {page.name}
                      </ScrollLink>
                    </MenuItem>
                  ))}
                  <MenuItem>
                    <LanguageSelect />
                  </MenuItem>
                </Box>
              )}
            </Box>

            <Box
              sx={{
                display: { xs: 'flex', md: 'none' },
                flex: 1,
                justifyContent: 'center'
              }}>
              <SilmexCoLogo />
            </Box>

            {/* Large Screen Menu */}
            <Box
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' }
              }}>
              <SilmexCoLogo />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                flexDirection: 'row-reverse',
                display: { xs: 'none', md: 'flex' }
              }}>
              {pages.reverse().map((page) => (
                <Box key={page.href} px={{ xs: 1, md: 4 }}>
                  <ScrollLink
                    onClick={() => navigationItemClicked(page)}
                    key={page.href}
                    to={page.href}
                    smooth={true}
                    spy={true}
                    activeClass={classes.active}
                    offset={page.href == 'home' ? -70 : 0}
                    duration={500}
                    style={{
                      color: theme.palette.primary.text,
                      cursor: 'pointer'
                    }}>
                    {page.name}
                  </ScrollLink>
                </Box>
              ))}
            </Box>

            {isMdOrLessScreen ? (
              <IconButton
                aria-label="phone"
                onClick={phoneNumberClicked}
                color="inherit"
                sx={{
                  display: 'flex',
                  marginLeft: { xs: 'none', md: 5 },
                  backgroundColor: theme.palette.accent.main,
                  padding: '0.5rem'
                }}>
                <img src={phone} alt="Phone Icon" style={{ height: '1.25rem' }} />
              </IconButton>
            ) : (
              <Button
                sx={{
                  ml: { xs: 'none', md: 5 },
                  backgroundColor: theme.palette.accent.main,
                  color: theme.palette.secondary.text
                }}
                variant="contained"
                size="small"
                onClick={phoneNumberClicked}>
                <span style={{ display: 'flex', alignItems: 'center', marginRight: '0.5rem' }}>
                  <img src={phone} alt="Phone Icon" style={{ height: '1.25rem' }} />
                </span>
                {phoneNumberToDisplay}
              </Button>
            )}

            <Box sx={{ ml: 5, display: { xs: 'none', md: 'flex' } }}>
              <LanguageSelect />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ElevationScroll>
  );
}
