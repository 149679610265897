import { useTranslation } from 'react-i18next';

import Photovoltaic from './assets/Products/PV/Photovoltaic.png';
import DMCController from './assets/Products/Controllers/DMCController.png';
import CFRMotor1 from './assets/Products/Motors/CFRMotor1.png';
import BenevelliAxel from './assets/Products/Axels/BenevelliAxel.png';

// import PBMCharger2 from './assets/Products/Chargers/PBMCharger2.png';
import Charging from './assets/Products/Chargers/Charging.png';
import Lightning from './assets/Products/Chargers/Lightning.png';
import Subject from './assets/Products/Chargers/Subject1.png';

import DMCAccelerator from './assets/Products/TowTruckParts/DMCAccelerator.png';
import Accelerator from './assets/Products/TowTruckParts/Accelerator.png';
import Converter from './assets/Products/TowTruckParts/Converter.png';
import Contactor from './assets/Products/TowTruckParts/Contactor.png';

import Forklift from './assets/Products/TowTrucks/Forklift.png';
import ForkliftDetail from './assets/Products/TowTrucks/ForkliftDetail.png';

import Factory from './assets/Products/PV/Factory.png';
import House from './assets/Products/PV/House.png';
import Mountain from './assets/Products/PV/Mountain.png';
import Wrench from './assets/Products/PV/Wrench.png';

const useProducts = () => {
  const { t } = useTranslation();

  const products = [
    {
      id: 'controllers',
      title: t('products.controllers.title'),
      description: t('products.controllers.description'),
      descriptions: [
        {
          text: t('products.controllers.description1'),
          filesToDownload: ['Controllers SIGMA.pdf'],
          filesToDownloadTitles: [t('products.controllers.descriptionFilesTitle')]
        },
        { text: t('products.controllers.description2') },
        { text: t('products.controllers.description3') }
      ],
      extendedDescription: t('products.controllers.extendedDescription'),
      fullDescription: t('products.controllers.description'),
      images: [DMCController],
      href: t('products.controllers.title'),
      details: []
    },
    {
      id: 'motors',
      title: t('products.motors.title'),
      description: t('products.motors.description'),
      descriptions: [
        { text: t('products.motors.description1') },
        { text: t('products.motors.description2') },
        { text: t('products.motors.description3') }
      ],
      extendedDescription: t('products.motors.extendedDescription'),
      images: [CFRMotor1],
      href: t('products.controllers.title'),
      details: [],
      contactModalDescription: t('products.motors.contactModalDescription'),
      contactModalInquiryFile: 'motorsInquiry.docx'
    },
    {
      id: 'axles',
      title: t('products.axles.title'),
      description: t('products.axles.description'),
      descriptions: [
        {
          text: t('products.axles.description1'),
          filesToDownload: [
            'VIEW Samples TX1_TX2.pdf',
            'TX1-Serie-Product-Guide.pdf',
            'TX2-Serie-Product-Guide.pdf'
          ],
          filesToDownloadTitles: [
            t('products.axles.descriptionFilesTitle1'),
            t('products.axles.descriptionFilesTitle2'),
            t('products.axles.descriptionFilesTitle3')
          ]
        },
        { text: t('products.axles.description2') }
      ],
      images: [BenevelliAxel],
      href: t('products.controllers.title'),
      details: [],
      contactModalDescription: t('products.axles.contactModalDescription'),
      contactModalInquiryFile: 'axelsInquiry.docx'
    },
    {
      id: 'chargers',
      title: t('products.chargers.title'),
      description: t('products.chargers.description'),
      descriptions: [
        { text: t('products.chargers.description1') },
        { text: t('products.chargers.description2') }
      ],
      images: [Subject],
      href: t('products.controllers.title'),
      details: [
        {
          id: 0,
          title: t('products.chargers.details.mounting.title'),
          description: t('products.chargers.details.mounting.description'),
          image: Charging
        },
        {
          id: 1,
          title: t('products.chargers.details.type.title'),
          description: t('products.chargers.details.type.description'),
          image: Lightning
        }
      ]
    },
    {
      id: 'vehicleEquipment',
      title: t('products.vehicleEquipment.title'),
      description: t('products.vehicleEquipment.description'),
      descriptions: [{ text: t('products.vehicleEquipment.description') }],
      images: [DMCAccelerator],
      href: t('products.controllers.title'),
      details: [
        {
          id: 0,
          title: t('products.vehicleEquipment.details.contactors.title'),
          description: t('products.vehicleEquipment.details.contactors.description'),
          image: Contactor,
          characteristicsFiles: ['amatek_contactors.pdf'],
          characteristicsTitles: [t('productDetails.productCharacteristics')]
        },
        {
          id: 1,
          title: t('products.vehicleEquipment.details.accelerators.title'),
          description: t('products.vehicleEquipment.details.accelerators.description'),
          image: Accelerator,
          characteristicsFiles: ['accelerators.pdf'],
          characteristicsTitles: [t('productDetails.productCharacteristics')]
        },
        {
          id: 2,
          title: t('products.vehicleEquipment.details.converters.title'),
          description: t('products.vehicleEquipment.details.converters.description'),
          image: Converter,
          characteristicsFiles: ['dc_dc_converters.pdf'],
          characteristicsTitles: [t('productDetails.productCharacteristics')]
        }
      ]
    },
    {
      id: 'towTractors',
      title: t('products.towTractors.title'),
      description: t('products.towTractors.description'),
      descriptions: [
        { text: t('products.towTractors.description1') },
        { text: t('products.towTractors.description2') },
        { text: t('products.towTractors.description3') }
      ],
      extendedDescription: t('products.towTractors.extendedDescription'),
      images: [Forklift],
      href: t('products.controllers.title'),
      details: [
        {
          id: 0,
          title: t('products.towTractors.details.mini.title'),
          description: t('products.towTractors.details.mini.description'),
          image: ForkliftDetail,
          characteristicsFiles: ['Technical parameters TEM3_TEM7.pdf'],
          characteristicsTitles: [t('products.towTractors.details.mini.fileTitle')]
        },
        {
          id: 1,
          title: t('products.towTractors.details.standard.title'),
          description: t('products.towTractors.details.standard.description'),
          image: ForkliftDetail,
          characteristicsFiles: [
            'Technical parameters TEM10_TEM12.pdf',
            'Technical parameters TEM15_TEM18.pdf'
          ],
          characteristicsTitles: [
            t('products.towTractors.details.standard.fileTitle1'),
            t('products.towTractors.details.standard.fileTitle1')
          ]
        },
        {
          id: 2,
          title: t('products.towTractors.details.max.title'),
          description: t('products.towTractors.details.max.description'),
          image: ForkliftDetail,
          characteristicsFiles: [
            'Technical parameters TEM20_TEM25.pdf',
            'Technical parameters TEM30_TEM35.pdf',
            'Technical parameters TEM40_TEM45.pdf'
          ],
          characteristicsTitles: [
            t('products.towTractors.details.max.fileTitle1'),
            t('products.towTractors.details.max.fileTitle2'),
            t('products.towTractors.details.max.fileTitle3')
          ]
        }
      ]
    },
    {
      id: 'pvSystems',
      title: t('products.pvSystems.title'),
      description: t('products.pvSystems.description'),
      descriptions: [{ text: t('products.pvSystems.description') }],
      fullDescription: t('products.pvSystems.description'),
      images: [Photovoltaic],
      href: t('products.controllers.title'),
      details: [
        {
          id: 0,
          title: t('products.pvSystems.details.residential.title'),
          description: t('products.pvSystems.details.residential.description'),
          image: House
        },
        {
          id: 1,
          title: t('products.pvSystems.details.industrial.title'),
          description: t('products.pvSystems.details.industrial.description'),
          image: Factory
        },
        {
          id: 2,
          title: t('products.pvSystems.details.offgrid.title'),
          description: t('products.pvSystems.details.offgrid.description'),
          image: Mountain
        },
        {
          id: 3,
          title: t('products.pvSystems.details.maintainence.title'),
          description: t('products.pvSystems.details.maintainence.description'),
          image: Wrench
        }
      ]
    }
  ];

  const getProductById = (id) => {
    return products.find((product) => product.id === id);
  };

  return { products, getProductById };
};

export default useProducts;
