import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import DownloadIcon from '../assets/Download.png';

const FileDownloader = (props) => {
  const { file, downloadableFilesTitles, index } = props;

  return (
    <Link
      to={`/files/${file}`}
      target="_blank"
      download
      style={{
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        backgroundColor: 'white',
        borderRadius: 5,
        padding: 10
      }}>
      <img src={DownloadIcon} height="30px" style={{ borderRadius: '0', marginRight: '8px' }} />
      <Typography sx={{ color: 'black' }}>{downloadableFilesTitles[index]}</Typography>
    </Link>
  );
};

export default FileDownloader;
