import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#F2F2F2',
      text: 'black',
      background: '#FAF8F8'
    },
    secondary: {
      main: '#219AC9',
      text: 'white'
    },
    accent: {
      main: '#00648B',
      text: '#ADADAD'
    },
    tertiary: {
      main: 'white'
    }
  }
});

export default theme;
