import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import React, { useState } from 'react';
import theme from '../../theme';
import { Link, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 600,
  width: '50%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 5
};

const GetInTouchButton = (props) => {
  const { product } = props;

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const contactClicked = () => {
    if (['motors', 'axles'].includes(product.id)) {
      handleOpenModal();
    } else {
      navigate('/', { state: { scrollTo: 'contact' } });
    }
  };

  return (
    <>
      <Box display={'flex'} justifyContent={'center'} width="100%" paddingBottom={10}>
        <Button
          sx={{
            maxWidth: '500px',
            minWidth: '200px',
            height: '50px',
            width: '50%',
            backgroundColor: theme.palette.accent.main,
            color: theme.palette.secondary.text
          }}
          variant="contained"
          size="small"
          onClick={contactClicked}>
          <Typography style={{ textTransform: 'none' }}>
            {t('productDetails.getInTouch')}
          </Typography>
        </Button>
      </Box>

      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              top: 5,
              right: 5,
              color: 'black'
            }}>
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t('productDetails.getInTouch')}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} whiteSpace={'pre-line'}>
            {product.contactModalDescription}
          </Typography>
          <Box pt={2}>
            <Link to={`/files/${product.contactModalInquiryFile}`} target="_blank" download>
              <Typography>{t('productDetails.downloadInqiry')}</Typography>
            </Link>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default GetInTouchButton;
