import React, { useState } from 'react';
import i18next from 'i18next';
import { Select, MenuItem } from '@mui/material';
import ReactCountryFlag from 'react-country-flag';

export function LanguageSelect() {
  const [language, setLanguage] = useState('bg');

  return (
    <Select
      disableUnderline={true}
      variant="standard"
      value={language}
      onChange={(newLanguage) => {
        setLanguage(newLanguage.target.value);
        i18next.changeLanguage(newLanguage.target.value);
      }}>
      <MenuItem value={'bg'}>
        <ReactCountryFlag svg countryCode="bg" />
      </MenuItem>
      <MenuItem value={'en'}>
        <ReactCountryFlag svg countryCode="us" />
      </MenuItem>
    </Select>
  );
}
