/* eslint-disable react/no-unknown-property */
import { Alert, Button, Snackbar, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import GoogleMapsPin from '../assets/ContactUs/googleMapsPin.png';
import theme from '../theme';
import { useUnderlineStyle } from '../styles/underlineStyle';
import { LoadingButton } from '@mui/lab';

export const ContactUs = () => {
  const { t } = useTranslation();
  const classes = useUnderlineStyle();

  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isEmailNotSent, setIsEmailNotSent] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsEmailSent(false);
    setIsEmailNotSent(false);
  };

  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const form = useRef();

  const contactDetails = [
    {
      id: 0,
      title: t('contactUs.details.email'),
      descriptions: ['zanovs@silmexco.com']
    },
    {
      id: 1,
      title: t('contactUs.details.personal'),
      descriptions: ['+359 88 775 1500', '+359 89 866 6682']
    },
    {
      id: 2,
      title: t('contactUs.details.stationary'),
      descriptions: ['+359 88 583 1402']
    },
    {
      id: 3,
      title: t('contactUs.details.street'),
      descriptions: [t('contactUs.details.viso')]
    },
    {
      id: 4,
      title: t('contactUs.details.number'),
      descriptions: ['60']
    },
    {
      id: 5,
      title: t('contactUs.details.postalCode'),
      descriptions: ['1115']
    },
    {
      id: 6,
      title: t('contactUs.details.city'),
      descriptions: [t('contactUs.details.sofia')]
    },
    {
      id: 7,
      title: t('contactUs.details.country'),
      descriptions: [t('contactUs.details.bulgaria')]
    }
  ];

  const sendEmail = async (e) => {
    setIsSendingEmail(true);
    e.preventDefault();

    emailjs
      .sendForm(
        'default_service',
        process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAIL_JS_KEY
      )
      .then(
        (result) => {
          setIsEmailSent(true);
          console.log(result.text);
        },
        (error) => {
          setIsEmailNotSent(true);
          console.log(error.text);
        }
      )
      .finally(() => {
        setIsSendingEmail(false);
        resetMailForm();
      });
  };

  const defaultProps = {
    center: {
      lat: 42.628507,
      lng: 23.4454
    },
    zoom: 16
  };

  const openInGoogleMaps = () => {
    window.open(
      'https://maps.google.com?q=' + defaultProps.center.lat + ',' + defaultProps.center.lng
    );
  };

  const resetMailForm = () => {
    form.current.reset();
  };

  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      flexDirection={'column'}
      sx={{ padding: { xs: 5, md: 10 } }}>
      <Box className={classes.container}>
        <Typography
          variant="h3"
          fontWeight={600}
          style={{ textAlign: 'center' }}
          className={classes.underlined}>
          {t('contactUs.title')}
        </Typography>
      </Box>

      <Box
        sx={{
          padding: { xs: 0, md: 3 }
        }}
        marginY={10}
        display={'flex'}
        flexDirection={'row'}
        width={'100%'}
        justifyContent={'center'}
        gap={'50px'}
        flexWrap={'wrap'}>
        <Box display={'flex'} flexDirection={'column'} sx={{ width: { xs: '100%', md: '45%' } }}>
          {contactDetails.map((contactDetail) => (
            <Box
              key={contactDetail.id}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              paddingTop={contactDetail.id == 3 ? 5 : 0}>
              <Typography color={'accent.main'}>{contactDetail.title}</Typography>

              <Box display={'flex'} flexDirection={'column'}>
                {contactDetail.descriptions.map((description) => (
                  <Typography key={description}>{description}</Typography>
                ))}
              </Box>
            </Box>
          ))}

          <Box
            bgcolor={'white'}
            p={1}
            borderRadius={2}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            mt={5}
            sx={{ cursor: 'pointer' }}
            onClick={openInGoogleMaps}>
            <Typography>{t('contactUs.googleMaps')}</Typography>

            <img src={GoogleMapsPin} width={30} height={30} />
          </Box>
        </Box>

        <Box sx={{ width: { xs: '100%', md: '45%' } }}>
          <form ref={form} onSubmit={sendEmail}>
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} gap={2}>
              <TextField id="name" name="name" label={t('contactUs.form.name')} required />

              <TextField id="email" name="email" label={t('contactUs.form.email')} required />

              <TextField id="subject" name="subject" label={t('contactUs.form.subject')} required />

              <TextField
                id="message"
                name="message"
                label={t('contactUs.form.message')}
                multiline={true}
                rows={10}
                required
              />

              <LoadingButton
                variant="outlined"
                loading={isSendingEmail}
                type="submit"
                sx={{
                  backgroundColor: theme.palette.accent.main,
                  color: theme.palette.secondary.text
                }}>
                {t('contactUs.form.send')}
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </Box>

      <Snackbar open={isEmailSent} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {t('contactUs.form.success')}
        </Alert>
      </Snackbar>

      <Snackbar open={isEmailNotSent} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {t('contactUs.form.error')}
        </Alert>
      </Snackbar>
    </Box>
  );
};
