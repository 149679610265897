import { useEffect } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AOS from 'aos';
import 'aos/dist/aos.css';

export function ProductCard(props) {
  const { product, sx } = props;

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      flexWrap={'wrap'}
      padding={2}
      sx={[sx, { maxWidth: { xs: '400px', md: '800px' } }]}
      bgcolor={'secondary.main'}
      minHeight={400}
      alignItems={'center'}
      justifyContent="center"
      borderRadius={5}
      marginBottom={5}
      position="relative">
      <Box
        display={'flex'}
        flexDirection={'column'}
        padding={5}
        sx={{ width: { xs: '100%', md: '50%' } }}>
        <Typography
          textAlign={'left'}
          variant="h5"
          fontWeight={'bold'}
          color={'secondary.text'}
          style={{ textTransform: 'none' }}>
          {product.title}
        </Typography>

        <Typography
          textAlign={'left'}
          paddingTop={3}
          color={'secondary.text'}
          style={{ textTransform: 'none' }}
          whiteSpace={'pre-line'}>
          {product.description}
        </Typography>
      </Box>

      <Box
        display={'flex'}
        flexDirection="column"
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ width: { xs: '50%', md: '35%' }, mb: { xs: 5, md: 0 } }}>
        <img src={product.images[0]} style={{ maxWidth: '90%', height: 'auto' }} />
      </Box>

      <div
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="20"
        style={{
          position: 'absolute',
          right: 20,
          bottom: 20
        }}>
        <Box
          p={1}
          borderRadius="50%"
          bgcolor="white"
          color="secondary.main"
          display="flex"
          justifyContent="center"
          alignItems="center">
          <ArrowForwardIosIcon />
        </Box>
      </div>
    </Box>
  );
}
