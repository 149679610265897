import { InformationBox } from '../components/InformationBox';
import CustomerFocus from '../assets/CustomerFocus.png';
import Innovation from '../assets/Innovation.png';
import Integrity from '../assets/Integrity.png';
import Quality from '../assets/Quality.png';
import Sustainability from '../assets/Sustainability.png';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { useUnderlineStyle } from '../styles/underlineStyle';
import WhiteBox from '../components/WhiteBox';
import Briefcase from '../assets/AboutUs/Briefcase.png';
import Company from '../assets/AboutUs/Company.png';
import Services from '../assets/AboutUs/Services.png';
import Sun from '../assets/AboutUs/Sun.png';
import Verified from '../assets/AboutUs/Verified.png';

export const AboutUs = () => {
  const { t } = useTranslation();
  const classes = useUnderlineStyle();

  return (
    <Box pt={10}>
      <Box className={classes.container}>
        <Typography
          variant="h3"
          fontWeight={600}
          px={10}
          style={{ textAlign: 'center' }}
          className={classes.underlined}>
          {t('aboutUs.title')}
        </Typography>
      </Box>

      <Box display={'flex'} flexDirection={'row'} justifyContent="center" flexWrap={'wrap'}>
        <Box
          my={5}
          sx={{
            maxWidth: { xs: '100%', md: '50%' },
            padding: 5
          }}
          alignItems={'center'}>
          <Typography
            variant="h5"
            fontWeight={600}
            textAlign={'center'}
            sx={{ marginBottom: '20px' }}>
            {t('aboutUs.who_we_are')}
          </Typography>

          <WhiteBox text={t('aboutUs.general_one')} src={Company} />

          <WhiteBox text={t('aboutUs.general_two')} src={Services} />

          <WhiteBox text={t('aboutUs.general_three')} src={Verified} />

          <WhiteBox text={t('aboutUs.general_four')} src={Briefcase} />

          <WhiteBox text={t('aboutUs.general_five')} src={Sun} />
        </Box>
      </Box>

      <Typography
        variant="h5"
        fontWeight={600}
        px={10}
        textAlign="center"
        sx={{ marginBottom: '20px' }}>
        {t('aboutUs.ourWork')}
      </Typography>

      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent="center"
        flexWrap={'wrap'}
        mt={5}
        sx={{ gap: { xs: '20px', md: '100px' } }}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent="center"
          minWidth={'350px'}
          width={'40%'}
          sx={{ gap: { xs: '20px', md: '100px' } }}>
          <InformationBox
            title={t('aboutUs.quality.title')}
            description={t('aboutUs.quality.description')}
            image={Quality}
          />

          <InformationBox
            title={t('aboutUs.innovation.title')}
            description={t('aboutUs.innovation.description')}
            image={Innovation}
          />

          <InformationBox
            title={t('aboutUs.sustainability.title')}
            description={t('aboutUs.sustainability.description')}
            image={Sustainability}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent="center"
          minWidth={'350px'}
          width={'40%'}
          sx={{ gap: { xs: '20px', md: '100px' }, paddingTop: { xs: '20px' } }}>
          <InformationBox
            title={t('aboutUs.integrity.title')}
            description={t('aboutUs.integrity.description')}
            image={Integrity}
          />

          <InformationBox
            title={t('aboutUs.customerFocus.title')}
            description={t('aboutUs.customerFocus.description')}
            image={CustomerFocus}
          />
        </Box>
      </Box>
    </Box>
  );
};
