import * as React from 'react';
import theme from '../theme.js';
import './test.css';
import StickyBox from 'react-sticky-box';
import { ProductCard } from '../components/ProductCard';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Button, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useProducts from '../productsRepository';
import { useUnderlineStyle } from '../styles/underlineStyle';
import { activeItemStyle } from '../styles/activeItemStyle.js';
import { Element, scroller, Link as ScrollLink } from 'react-scroll';

export function Products() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useUnderlineStyle();
  const activeItem = activeItemStyle();

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const { products } = useProducts();

  const handleClickScroll = (product) => {
    scroller.scrollTo(product.id, {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: -70
    });
  };

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
      pt={10}>
      <Box className={classes.container}>
        <Typography
          variant="h3"
          fontWeight={600}
          px={10}
          style={{ textAlign: 'center' }}
          className={classes.underlined}>
          {t('products.title')}
        </Typography>
      </Box>

      <Box
        p={3}
        my={5}
        display={'flex'}
        alignItems={'flex-start'}
        justifyContent={'center'}
        gap={10}>
        {!isSmallScreen && (
          <StickyBox offsetTop={150}>
            <Box display={'flex'} flexDirection={'column'} gap={2} width={400}>
              {products.map((product) => (
                <ScrollLink
                  onClick={() => handleClickScroll(product)}
                  key={product.id}
                  to={product.id}
                  smooth={true}
                  spy={true}
                  activeClass={activeItem.active}
                  offset={-200}
                  duration={500}
                  style={{
                    color: theme.palette.primary.text,
                    cursor: 'pointer'
                  }}>
                  {product.title}
                </ScrollLink>
              ))}
            </Box>
          </StickyBox>
        )}

        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
          {products.map((product) => (
            <Element key={product.id}>
              <Button
                id={product.id}
                onClick={() => {
                  navigate(`products/${product.id}`);
                }}>
                <ProductCard product={product} />
              </Button>
            </Element>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
