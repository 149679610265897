import React, { useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import { Container } from '@mui/material';
import { AboutUs } from './AboutUs';
import { ContactUs } from './ContactUs';
import { TopNavigation } from '../components/TopNavigation.js';
import { Landing } from './Landing';
import { Products } from './Products.js';
import theme from '../theme';

export function Home() {
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      scroller.scrollTo(location.state.scrollTo, {
        duration: 500,
        delay: 0,
        smooth: true,
        offset: location.state.scrollTo === 'home' ? -70 : 20
      });
    }
  }, [location.state]);

  return (
    <Container
      maxWidth={false}
      disableGutters
      style={{ backgroundColor: theme.palette.primary.background }}>
      <TopNavigation />

      <Element name="home">
        <Landing />
      </Element>

      <Element name="aboutUs">
        <AboutUs />
      </Element>

      <Element name="products">
        <Products />
      </Element>

      <Element name="contact">
        <ContactUs />
      </Element>
    </Container>
  );
}
